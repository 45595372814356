
export const ALL_USERS = {
  REQUEST: "ALL_USERS_REQUEST",
  SUCCESS: "ALL_USERS_SUCCESS",
  FAIL: "ALL_USERS_FAIL",
};
export const SINGLE_USER = {
  REQUEST: "SINGLE_USER_REQUEST",
  SUCCESS: "SINGLE_USER_SUCCESS",
  FAIL: "SINGLE_USER_FAIL",
};
export const USER_STAKES = {
  REQUEST: "USER_STAKES_REQUEST",
  SUCCESS: "USER_STAKES_SUCCESS",
  FAIL: "USER_STAKES_FAIL",
};
export const USER_TEAM = {
  REQUEST: "USER_TEAM_REQUEST",
  SUCCESS: "USER_TEAM_SUCCESS",
  FAIL: "USER_TEAM_FAIL",
};
export const USER_TEAM_REWARD = {
  REQUEST: "USER_TEAM_REWARD_REQUEST",
  SUCCESS: "USER_TEAM_REWARD_SUCCESS",
  FAIL: "USER_TEAM_REWARD_FAIL",
};
export const USER_REWARDS = {
  REQUEST: "USER_REWARD_REQUEST",
  SUCCESS: "USER_REWARD_SUCCESS",
  FAIL: "USER_REWARD_FAIL",
};
export const USER_WITHDRAW = {
  REQUEST: "USER_WITHDRAW_REQUEST",
  SUCCESS: "USER_WITHDRAW_SUCCESS",
  FAIL: "USER_WITHDRAW_FAIL",
};
export const USER_SALE = {
  REQUEST: "USER_SALE_REQUEST",
  SUCCESS: "USER_SALE_SUCCESS",
  FAIL: "USER_SALE_FAIL",
};
export const USER_FundTransFer = {
  REQUEST: "USER_FundTransFer_REQUEST",
  SUCCESS: "USER_FundTransFer_SUCCESS",
  FAIL: "USER_FundTransFer_FAIL",
};
export const USER_PROFILE = {
  REQUEST: "USER_PROFILE_REQUEST",
  SUCCESS: "USER_PROFILE_SUCCESS",
  FAIL: "USER_PROFILE_FAIL",
};
export const USER_DETAIL = {
  REQUEST: "USER_DETAIL_REQUEST",
  SUCCESS: "USER_DETAIL_SUCCESS",
  FAIL: "USER_DETAIL_FAIL",
  RESET: "USER_DETAIL_RESET",
};

export const UPDATE_USER = {
  REQUEST: "UPDATE_USER_REQUEST",
  SUCCESS: "UPDATE_USER_SUCCESS",
  FAIL: "UPDATE_USER_FAIL",
  RESET: "UPDATE_USER_RESET",
};
export const STAKE_HISTORY = {
  REQUEST: 'STAKE_HISTORY_REQUEST',
  SUCCESS: 'STAKE_HISTORY_SUCCESS',
  FAIL: 'STAKE_HISTORY_FAIL'
};

export const UPDATE_STAKE = {
  REQUEST: "UPDATE_STAKE_REQUEST",
  SUCCESS: "UPDATE_STAKE_SUCCESS",
  FAIL: "UPDATE_STAKE_FAIL",
  RESET: "UPDATE_STAKE_RESET",
};
