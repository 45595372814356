import React from "react";
import { Navbar } from "reactstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
// import { useAuth0 } from "../../../authServices/auth0/auth0Service";
// import {
//   logoutWithJWT,
//   logoutWithFirebase,
// } from "../../../redux/actions/auth/loginActions";
// import NavbarBookmarks from "./NavbarBookmarks";
import NavbarUser from "./NavbarUser";
// import themeConfig from "../../../configs/themeConfig";
// import customizerReducer from "../../../redux/reducers/customizer/customizer";
import { changeMode } from "../../../redux/actions/customizer";
import * as Icon from "react-feather";
import { IconButton } from "@mui/material";

// const UserName = (props) => {
//   let username = "";
//   if (props.userdata !== undefined) {
//     username = props.userdata.name;
//   } else if (props.user.login.values !== undefined) {
//     username = props.user.login.values.name;
//     if (
//       props.user.login.values.loggedInWith !== undefined &&
//       props.user.login.values.loggedInWith === "jwt"
//     ) {
//       username = props.user.login.values.loggedInUser.name;
//     }
//   } else {
//     username = "John Doe";
//   }

//   return username;
// };
const ThemeNavbar = (props) => {
  // const { user } = useAuth0();
  const { horizontal = true } = props;
  const dispatch = useDispatch();
  const customizer = useSelector((state) => state.customizer.customizer);
  const { loginData } = useSelector((state) => state.auth.login);
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];

  const themeChangeHandler = () => {
    localStorage.setItem(
      "theme",
      customizer?.theme === "dark" ? "light" : "dark"
    );
    dispatch(changeMode(customizer?.theme === "dark" ? "light" : "dark"));
  };

  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light":
              props.navbarColor === "default" ||
              !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !horizontal),
            "navbar-static-top": props.navbarType === "static" && !horizontal,
            "fixed-top": props.navbarType === "sticky" || horizontal,
            scrolling: horizontal && props.scrolling,
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-end align-items-center"
              id="navbar-mobile"
            >
              {/* {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">Vuexy</h2>
                </div>
              ) : null} */}
              {customizer?.theme === "dark" ? (
                <IconButton
                  onClick={themeChangeHandler}
                  className="cursor-pointer text-secondary"
                >
                  <Icon.Sun />
                </IconButton>
              ) : (
                <IconButton
                  onClick={themeChangeHandler}
                  className="cursor-pointer rounded-5"
                >
                  <Icon.Moon />
                </IconButton>
              )}

              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                userData={loginData}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth,
  };
};

export default connect(mapStateToProps)(ThemeNavbar);
