import { USER_SELL_STATUS } from "../../constant/userSellKGC/userSellKGC";


const saleInitialState = {
  saleLoading: false,
  saleData: null,
  saleError: null,
};

export const saleReducer = (state = saleInitialState, action) => {
  switch (action.type) {
    case USER_SELL_STATUS.REQUEST:
      return {
        ...state,
        saleLoading: true,
        saleData: null,
        saleError: null,
      };
    case USER_SELL_STATUS.SUCCESS:
      return {
        ...state,
        saleLoading: false,
        saleData: action.payload,
        saleError: null,
      };
    case USER_SELL_STATUS.FAIL:
      return {
        ...state,
        saleLoading: false,
        saleData: null,
        saleError: action.payload,
      };

    default:
      return state;
  }
};
