export const ALL_GIFT_REWARDS = {
  REQUEST: "ALL_GIFT_REWARDS_REQUEST",
  SUCCESS: "ALL_GIFT_REWARDS_SUCCESS",
  FAIL: "ALL_GIFT_REWARDS_FAIL",
};
export const GIFT_REWARDS_STATUS = {
  REQUEST: "GIFT_REWARDS_STATUS_REQUEST",
  SUCCESS: "GIFT_REWARDS_STATUS_SUCCESS",
  FAIL: "GIFT_REWARDS_STATUS_FAIL",
};
