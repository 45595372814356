import { USER_WITHDRAW_STATUS } from "../../constant/userWithdraw/userWithdrawConstants";



const withdrawInitialState = {
  withdrawLoading: false,
  withdrawData: null,
  withdrawError: null,
};

export const withdrawReducer = (state = withdrawInitialState, action) => {
  switch (action.type) {
    case USER_WITHDRAW_STATUS.REQUEST:
      return {
        ...state,
        withdrawLoading: true,
        withdrawData: null,
        withdrawError: null,
      };
    case USER_WITHDRAW_STATUS.SUCCESS:
      return {
        ...state,
        withdrawLoading: false,
        withdrawData: action.payload,
        withdrawError: null,
      };
    case USER_WITHDRAW_STATUS.FAIL:
      return {
        ...state,
        withdrawLoading: false,
        withdrawData: null,
        withdrawError: action.payload,
      };

    default:
      return state;
  }
};
