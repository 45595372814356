import {
  ALL_GIFT_REWARDS,
  GIFT_REWARDS_STATUS,
} from "../../constant/giftRewards/giftRewardsConstants";

const giftRewardsInitialState = {
  giftRewardsLoading: false,
  giftRewardsData: null,
  giftRewardsError: null,

  giftStatusLoading: false,
  giftStatusData: null,
  giftStatusError: null,
};

export const giftRewardsReducer = (state = giftRewardsInitialState, action) => {
  switch (action.type) {
    case ALL_GIFT_REWARDS.REQUEST:
      return {
        ...state,
        giftRewardsLoading: true,
        giftRewardsData: null,
        giftRewardsError: null,
      };
    case ALL_GIFT_REWARDS.SUCCESS:
      return {
        ...state,
        giftRewardsLoading: false,
        giftRewardsData: action.payload,
        giftRewardsError: null,
      };
    case ALL_GIFT_REWARDS.FAIL:
      return {
        ...state,
        giftRewardsLoading: false,
        giftRewardsData: null,
        giftRewardsError: action.payload,
      };
    case GIFT_REWARDS_STATUS.REQUEST:
      return {
        ...state,
        giftStatusLoading: true,
        giftStatusData: null,
        giftStatusError: null,
      };
    case GIFT_REWARDS_STATUS.SUCCESS:
      return {
        ...state,
        giftStatusLoading: false,
        giftStatusData: action.payload,
        giftStatusError: null,
      };
    case GIFT_REWARDS_STATUS.FAIL:
      return {
        ...state,
        giftStatusLoading: false,
        giftStatusData: null,
        giftStatusError: action.payload,
      };
    default:
      return state;
  }
};
