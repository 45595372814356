import {
  ALL_USERS,
  UPDATE_USER,
  USER_DETAIL,
  USER_STAKES,
  USER_REWARDS,
  SINGLE_USER,
  STAKE_HISTORY,
  USER_TEAM,
  USER_WITHDRAW,
  USER_SALE,
  USER_FundTransFer,
  USER_PROFILE,
  USER_TEAM_REWARD,
} from "../../constant/users/userConstants";

const userDefaultState = {
  allUsersLoading: false,
  allUsersData: null,
  allUsersError: null,

  singleUserLoading: false,
  singleUserData: null,
  singleUserError: null,

  userStakingLoading: false,
  userStakingData: null,
  userStakingError: null,

  userTeamLoading: false,
  userTeamData: null,
  userTeamError: null,

  userRewardsLoading: false,
  userRewardsData: null,
  userRewardsError: null,

  userWithdrawLoading: false,
  userWithdrawData: null,
  userWithdrawError: null,

  userSaleLoading: false,
  userSaleData: null,
  userSaleError: null,


  userProfileLoading: false,
  userProfileData: null,
  userProfileError: null,

  teamRewardLoading: false,
  teamRewardData: null,
  teamRewardError: null,

  userFundsTransferLoading: false,
  userFundsTransferData: null,
  userFundsTransferError: null,

  userDetailLoading: false,
  userDetailData: null,
  userDetailError: null,

  updateUserLoading: false,
  updateUserData: null,
  updateUserError: null,

  stakingHistoryLoading:false,
  stakingHistoryData:null,
  stakingHistoryError:null,
};

export const usersReducer = (state = userDefaultState, action) => {
  switch (action.type) {
    case ALL_USERS.REQUEST:
      return {
        ...state,
        allUsersLoading: true,
        allUsersData: null,
        allUsersError: null,
      };
    case ALL_USERS.SUCCESS:
      return {
        ...state,
        allUsersLoading: false,
        allUsersData: action.payload,
        // allUsersData: { ...action.payload, statusCode: action.status },
        allUsersError: null,
      };
    case ALL_USERS.FAIL:
      return {
        ...state,
        allUsersLoading: false,
        allUsersData: null,
        allUsersError: action.payload,
      };
      //single user
    case SINGLE_USER.REQUEST:
      return {
        ...state,
        singleUserLoading: true,
        singleUserData: null,
        singleUserError: null,
      };
    case SINGLE_USER.SUCCESS:
      return {
        ...state,
        singleUserLoading: false,
        singleUserData: { ...action.payload, statusCode: action.status },
        singleUserError: null,
      };
    case SINGLE_USER.FAIL:
      return {
        ...state,
        singleUserLoading: false,
        singleUserData: null,
        singleUserError: action.payload,
      };
    //Stakes
    case USER_STAKES.REQUEST:
      return {
        ...state,
        userStakingLoading: true,
        userStakingData: null,
        userStakingError: null,
      };
    case USER_STAKES.SUCCESS:
      return {
        ...state,
        userStakingLoading: false,
        userStakingData: action.payload,
        userStakingError: null,
      };
    case USER_STAKES.FAIL:
      return {
        ...state,
        userStakingLoading: false,
        userStakingData: null,
        userStakingError: action.payload,
      };
      //user team details
    case USER_TEAM.REQUEST:
      return {
        ...state,
        userTeamLoading: true,
        userTeamData: null,
        userTeamError: null,
      };
    case USER_TEAM.SUCCESS:
      return {
        ...state,
        userTeamLoading: false,
        userTeamData: action.payload,
        userTeamError: null,
      };
    case USER_TEAM.FAIL:
      return {
        ...state,
        userTeamLoading: false,
        userTeamData: null,
        userTeamError: action.payload,
      };
    //Rewards
    case USER_REWARDS.REQUEST:
      return {
        ...state,
        userRewardsLoading: true,
        userRewardsData: null,
        userRewardsError: null,
      };
    case USER_REWARDS.SUCCESS:
      return {
        ...state,
        userRewardsLoading: false,
        userRewardsData: action.payload,
        userRewardsError: null,
      };
    case USER_REWARDS.FAIL:
      return {
        ...state,
        userRewardsLoading: false,
        userRewardsData: null,
        userRewardsError: action.payload,
      };


       //Withdraw
    case USER_WITHDRAW.REQUEST:
      return {
        ...state,
        userWithdrawLoading: true,
        userWithdrawData: null,
        userWithdrawError: null,
      };
    case USER_WITHDRAW.SUCCESS:
      return {
        ...state,
        userWithdrawLoading: false,
        userWithdrawData: action.payload,
        userWithdrawError: null,
      };
    case USER_WITHDRAW.FAIL:
      return {
        ...state,
        userWithdrawLoading: false,
        userWithdrawData: null,
        userWithdrawError: action.payload,
      };



       //Sale KGC
    case USER_SALE.REQUEST:
      return {
        ...state,
        userSaleLoading: true,
        userSaleData: null,
        userSaleError: null,
      };
    case USER_SALE.SUCCESS:
      return {
        ...state,
        userSaleLoading: false,
        userSaleData: action.payload,
        userSaleError: null,
      };
    case USER_SALE.FAIL:
      return {
        ...state,
        userSaleLoading: false,
        userSaleData: null,
        userSaleError: action.payload,
      };



       // User Profile Details
    case USER_PROFILE.REQUEST:
      return {
        ...state,
        userProfileLoading: true,
        userProfileData: null,
        userProfileError: null,
      };
    case USER_PROFILE.SUCCESS:
      return {
        ...state,
        userProfileLoading: false,
        userProfileData: action.payload,
        userProfileError: null,
      };
    case USER_PROFILE.FAIL:
      return {
        ...state,
        userProfileLoading: false,
        userProfileData: null,
        userProfileError: action.payload,
      };


       // User user Team Reward Details
    case USER_TEAM_REWARD.REQUEST:
      return {
        ...state,
        teamRewardLoading: true,
        teamRewardData: null,
        teamRewardError: null,
      };
    case USER_TEAM_REWARD.SUCCESS:
      return {
        ...state,
        teamRewardLoading: false,
        teamRewardData: action.payload,
        teamRewardError: null,
      };
    case USER_TEAM_REWARD.FAIL:
      return {
        ...state,
        teamRewardLoading: false,
        teamRewardData: null,
        teamRewardError: action.payload,
      };


       // Funds Transfer List
    case USER_FundTransFer.REQUEST:
      return {
        ...state,
        userFundsTransferLoading: true,
        userFundsTransferData: null,
        userFundsTransferError: null,
      };
    case USER_FundTransFer.SUCCESS:
      return {
        ...state,
        userFundsTransferLoading: false,
        userFundsTransferData: action.payload,
        userFundsTransferError: null,
      };
    case USER_FundTransFer.FAIL:
      return {
        ...state,
        userFundsTransferLoading: false,
        userFundsTransferData: null,
        userFundsTransferError: action.payload,
      };


    case USER_DETAIL.REQUEST:
      return {
        ...state,
        userDetailLoading: true,
        userDetailData: null,
        userDetailError: null,
      };
    case USER_DETAIL.SUCCESS:
      return {
        ...state,
        userDetailLoading: false,
        userDetailData: { ...action.payload, statusCode: action.status },
        userDetailError: null,
      };
    case USER_DETAIL.FAIL:
      return {
        ...state,
        userDetailLoading: false,
        userDetailData: null,
        userDetailError: action.payload,
      };
    case USER_DETAIL.RESET:
      return {
        ...state,
        userDetailLoading: false,
        userDetailData: null,
        userDetailError: null,
      };

    case UPDATE_USER.REQUEST:
      return {
        ...state,
        updateUserLoading: true,
        updateUserData: null,
        updateUserError: null,
      };
    case UPDATE_USER.SUCCESS:
      return {
        ...state,
        updateUserLoading: false,
        updateUserData: { ...action.payload, statusCode: action.status },
        updateUserError: null,
      };
    case UPDATE_USER.FAIL:
      return {
        ...state,
        updateUserLoading: false,
        updateUserData: null,
        updateUserError: action.payload,
      };
    case UPDATE_USER.RESET:
      return {
        ...state,
        updateUserLoading: false,
        updateUserData: null,
        updateUserError: null,
      };
      case STAKE_HISTORY.REQUEST:
        return {
          ...state,
          stakingHistoryLoading: true,
          stakingHistoryData: null,
          stakingHistoryError: null,
        };
      case STAKE_HISTORY.SUCCESS:
        return {
          ...state,
          stakingHistoryLoading: false,
          stakingHistoryData: action.payload,
          stakingHistoryError: null,
        };
      case STAKE_HISTORY.FAIL:
        return {
          ...state,
          stakingHistoryLoading: false,
          stakingHistoryData: null,
          stakingHistoryError: action.payload,
        };
    default:
      return state;
  }
};
