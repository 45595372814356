import { USER_FundTransFer_STATUS } from "../../constant/userFundtransFer/userFundtransFer";



const saleInitialState = {
  fundTransferLoading: false,
  fundTransferData: null,
  fundTransferError: null,
};

export const fundTransferReducer = (state = saleInitialState, action) => {
  switch (action.type) {
    case USER_FundTransFer_STATUS.REQUEST:
      return {
        ...state,
        fundTransferLoading: true,
        fundTransferData: null,
        fundTransferError: null,
      };
    case USER_FundTransFer_STATUS.SUCCESS:
      return {
        ...state,
        fundTransferLoading: false,
        fundTransferData: action.payload,
        fundTransferError: null,
      };
    case USER_FundTransFer_STATUS.FAIL:
      return {
        ...state,
        fundTransferLoading: false,
        fundTransferData: null,
        fundTransferError: action.payload,
      };

    default:
      return state;
  }
};
