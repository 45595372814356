import { USER_PROFILE_STATUS } from "../../constant/userProfileDetails/userProfileDetails";


const saleInitialState = {
  userProfileLoading: false,
  userProfileData: null,
  userProfileError: null,
};

export const userProfileReducer = (state = saleInitialState, action) => {
  switch (action.type) {
    case USER_PROFILE_STATUS.REQUEST:
      return {
        ...state,
        userProfileLoading: true,
        userProfileData: null,
        userProfileError: null,
      };
    case USER_PROFILE_STATUS.SUCCESS:
      return {
        ...state,
        userProfileLoading: false,
        userProfileData: action.payload,
        userProfileError: null,
      };
    case USER_PROFILE_STATUS.FAIL:
      return {
        ...state,
        userProfileLoading: false,
        userProfileData: null,
        userProfileError: action.payload,
      };

    default:
      return state;
  }
};
