import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";

import usersReducers from "./users/";
import dashboardReducer from "./dashboard/dashboardReducer";
import { giftRewardsReducer } from "./giftRewards/giftRewardsReducer";
import { otherGiftRewardsReducer } from "./otherRewards/otherRewardsReducer";
import { stakingPageListReducer } from "./stakingPage/stakingPageReducer";
import supportReducer from "./support/supportReducer";
import { withdrawReducer } from "./userWithdrawList/userWithdrawListReducer";
import { saleReducer } from "./userSaleList/userSaleListReducer";
import { fundTransferReducer } from "./userFundtransFerList/userFundtransFerListReducer";
import { userProfileReducer } from "./userProfileDetails/userProfileDetailsReducer";
import { teamRewardReducer } from "./userTeamReward/userTeamRewardReducer";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  users: usersReducers,
  dashboard: dashboardReducer,
  giftRewards: giftRewardsReducer,
  withdrawList: withdrawReducer,
  saleList: saleReducer,
  teamReward: teamRewardReducer,
  userProfile: userProfileReducer,
  fundsTransferList: fundTransferReducer,
  otherRewards: otherGiftRewardsReducer,
  stakingPage: stakingPageListReducer,
  support: supportReducer
});

export default rootReducer;
