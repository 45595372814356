import {
  DASHBOARD,
  SALE_KGC,
  STAKE_REWARDS,
} from "../../constant/dashboard/dashboardConstants";

const dashboardDefaultState = {
  dashboardLoading: false,
  dashboardData: null,
  dashboardError: null,

  stakeRewardsLoading: false,
  stakeRewardsData: null,
  stakeRewardsError: false,

  saleKGCLoading: false,
  saleKGCData: null,
  saleKGCError: false,
};

const dashboardReducer = (state = dashboardDefaultState, action) => {
  switch (action.type) {
    case DASHBOARD.REQUEST:
      return {
        ...state,
        dashboardLoading: true,
        dashboardData: null,
        dashboardError: null,
      };
    case DASHBOARD.SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        dashboardData: action.payload,
        // dashboardData: { ...action.payload, statusCode: action.status },
        dashboardError: null,
      };
    case DASHBOARD.FAIL:
      return {
        ...state,
        dashboardLoading: false,
        dashboardData: null,
        dashboardError: action.payload,
      };
    case STAKE_REWARDS.REQUEST:
      return {
        ...state,
        stakeRewardsLoading: true,
        stakeRewardsData: null,
        stakeRewardsError: null,
      };
    case STAKE_REWARDS.SUCCESS:
      return {
        ...state,
        stakeRewardsLoading: false,
        stakeRewardsData: action.payload,
        stakeRewardsError: null,
      };
    case STAKE_REWARDS.FAIL:
      return {
        ...state,
        stakeRewardsLoading: false,
        stakeRewardsData: null,
        stakeRewardsError: action.payload,
      };


      case SALE_KGC.REQUEST:
      return {
        ...state,
        saleKGCLoading: true,
        saleKGCData: null,
        saleKGCError: null,
      };
    case SALE_KGC.SUCCESS:
      return {
        ...state,
        saleKGCLoading: false,
        saleKGCData: action.payload,
        saleKGCError: null,
      };
    case SALE_KGC.FAIL:
      return {
        ...state,
        saleKGCLoading: false,
        saleKGCData: null,
        saleKGCError: action.payload,
      };

    default:
      return state;
  }
};
export default dashboardReducer;
