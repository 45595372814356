import { ALL_TICKETS, SUPPORT, TICKETS_BYID, TODAY_TICKETS, UPDATE_TICKETS_BYID } from "../../constant/support/supportConstants";


const supportDashboardDefaultState = {
    supportDashboardLoading: false,
    supportDashboardData: null,
    supportDashboardError: null,

    todayTicketsLoading: false,
    todayTicketsData: null,
    todayTicketsError: null,

    allTicketsLoading: false,
    allTicketsData: null,
    allTicketsError: null,

    TicketByIdLoading: false,
    TicketByIdData: null,
    TicketByIdError: null,

    updateTicketByIdLoading: false,
    updateTicketByIdData: null,
    updateTicketByIdError: null,

};

const supportReducer = (state = supportDashboardDefaultState, action) => {
    switch (action.type) {
        case SUPPORT.REQUEST:
            return {
                ...state,
                supportDashboardLoading: true,
                supportDashboardData: null,
                supportDashboardError: null,
            };
        case SUPPORT.SUCCESS:
            return {
                ...state,
                supportDashboardLoading: false,
                supportDashboardData: action.payload,
                // supportDashboardData: { ...action.payload, statusCode: action.status },
                supportDashboardError: null,
            };
        case SUPPORT.FAIL:
            return {
                ...state,
                supportDashboardLoading: false,
                supportDashboardData: null,
                supportDashboardError: action.payload,
            };

        // Today Support Tickets


        case TODAY_TICKETS.REQUEST:
            return {
                ...state,
                todayTicketsLoading: true,
                todayTicketsData: null,
                todayTicketsError: null,
            };
        case TODAY_TICKETS.SUCCESS:
            return {
                ...state,
                todayTicketsLoading: false,
                todayTicketsData: action.payload,
                // todayTicketsData: { ...action.payload, statusCode: action.status },
                todayTicketsError: null,
            };
        case TODAY_TICKETS.FAIL:
            return {
                ...state,
                todayTicketsLoading: false,
                todayTicketsData: null,
                todayTicketsError: action.payload,
            };


            // Get All Tickets


        case ALL_TICKETS.REQUEST:
            return {
                ...state,
                allTicketsLoading: true,
                allTicketsData: null,
                allTicketsError: null,
            };
        case ALL_TICKETS.SUCCESS:
            return {
                ...state,
                allTicketsLoading: false,
                allTicketsData: action.payload,
                // allTicketsData: { ...action.payload, statusCode: action.status },
                allTicketsError: null,
            };
        case ALL_TICKETS.FAIL:
            return {
                ...state,
                allTicketsLoading: false,
                allTicketsData: null,
                allTicketsError: action.payload,
            };


                // Get Tickets By Id


        case TICKETS_BYID.REQUEST:
            return {
                ...state,
                TicketByIdLoading: true,
                TicketByIdData: null,
                TicketByIdError: null,
            };
        case TICKETS_BYID.SUCCESS:
            return {
                ...state,
                TicketByIdLoading: false,
                TicketByIdData: action.payload,
                // TicketByIdData: { ...action.payload, statusCode: action.status },
                TicketByIdError: null,
            };
        case TICKETS_BYID.FAIL:
            return {
                ...state,
                TicketByIdLoading: false,
                TicketByIdData: null,
                TicketByIdError: action.payload,
            };


             // Update Tickets By Id


        case UPDATE_TICKETS_BYID.REQUEST:
            return {
                ...state,
                updateTicketByIdLoading: true,
                updateTicketByIdData: null,
                updateTicketByIdError: null,
            };
        case UPDATE_TICKETS_BYID.SUCCESS:
            return {
                ...state,
                updateTicketByIdLoading: false,
                updateTicketByIdData: action.payload,
                // updateTicketByIdData: { ...action.payload, statusCode: action.status },
                updateTicketByIdError: null,
            };
        case UPDATE_TICKETS_BYID.FAIL:
            return {
                ...state,
                updateTicketByIdLoading: false,
                updateTicketByIdData: null,
                updateTicketByIdError: action.payload,
            };

        default:
            return state;
    }
};
export default supportReducer;
