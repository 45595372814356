export const DASHBOARD = {
  REQUEST: "DASHBOARD_REQUEST",
  SUCCESS: "DASHBOARD_SUCCESS",
  FAIL: "DASHBOARD_FAIL",
};

export const STAKE_REWARDS = {
  REQUEST: "STAKE_REWARDS_REQUEST",
  SUCCESS: "STAKE_REWARDS_SUCCESS",
  FAIL: "STAKE_REWARDS_FAIL",
};

export const  SALE_KGC = {
  REQUEST: "SALE_KGC_REQUEST",
  SUCCESS: "SALE_KGC_SUCCESS",
  FAIL: "SALE_KGC_FAIL",
};
