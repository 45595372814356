import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import * as Icon from "react-feather";
import { history } from "../../../history";
import { connect } from "react-redux";

const UserDropdown = () => {
  return (
    <DropdownMenu right style={{ width: "max-content" }}>
      <DropdownItem
        onClick={(e) => {
          e.preventDefault();
          history.push("/change-password");
        }}
      >
        <Icon.Key size={14} className="mr-50" />
        <span className="align-middle">Change Password</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="/pages/login"
        onClick={(e) => {
          e.preventDefault();
          localStorage.clear();
          history.push("/login");
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};
const userData = JSON.parse(localStorage.getItem("userData"));
class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
  };

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult });
    });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  removeItem = (id) => {
    let cart = this.state.shoppingCart;

    let updatedCart = cart.filter((i) => i.id !== id);

    this.setState({
      shoppingCart: updatedCart,
    });
  };

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown });
  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props?.userData?.userName || userData?.userName}
              </span>
              <span className="user-status">
                {this.props?.userData?.status || userData?.status}
              </span>
            </div>
            <span data-tour="user">
              <img
                // src={this.props.userImg}
                src={
                  this.props?.userData?.profilePicture ||
                  userData?.profilePicture ||
                  this.props.loginData?.profilePicture
                }
                className="round"
                height="40"
                width="40"
                alt=""
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth.login, // Assuming 'auth' is your reducer and 'login' is the slice
});

// Connect the component to Redux store
export default connect(mapStateToProps)(NavbarUser);
// export default NavbarUser;
