import { STAKING_PAGE_LIST } from "../../constant/stakingPage/stakingPageConstants";

const stakingPageListInitialState = {
  stakingPageListLoading: false,
  stakingPageListData: null,
  stakingPageListError: null,
};

export const stakingPageListReducer = (
  state = stakingPageListInitialState,
  action
) => {
  switch (action.type) {
    case STAKING_PAGE_LIST.REQUEST:
      return {
        ...state,
        stakingPageListLoading: true,
        stakingPageListData: null,
        stakingPageListError: null,
      };
    case STAKING_PAGE_LIST.SUCCESS:
      return {
        ...state,
        stakingPageListLoading: false,
        stakingPageListData: action.payload,
        stakingPageListError: null,
      };
    case STAKING_PAGE_LIST.FAIL:
      return {
        ...state,
        stakingPageListLoading: false,
        stakingPageListData: null,
        stakingPageListError: action.payload,
      };
    default:
      return state;
  }
};
