import { USER_TEAM_REWARD_STATUS } from "../../constant/userTeamReward/userTeamRewardConstants";


const withdrawInitialState = {
  teamRewardLoading: false,
  teamRewardData: null,
  teamRewardError: null,
};

export const teamRewardReducer = (state = withdrawInitialState, action) => {
  switch (action.type) {
    case USER_TEAM_REWARD_STATUS.REQUEST:
      return {
        ...state,
        teamRewardLoading: true,
        teamRewardData: null,
        teamRewardError: null,
      };
    case USER_TEAM_REWARD_STATUS.SUCCESS:
      return {
        ...state,
        teamRewardLoading: false,
        teamRewardData: action.payload,
        teamRewardError: null,
      };
    case USER_TEAM_REWARD_STATUS.FAIL:
      return {
        ...state,
        teamRewardLoading: false,
        teamRewardData: null,
        teamRewardError: action.payload,
      };

    default:
      return state;
  }
};
