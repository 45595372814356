import { OTHER_GIFT_REWARDS } from "../../constant/otherRewards/otherRewardsConstants";


const otherGiftRewardsInitialState = {
  otherGiftRewardsLoading: false,
  otherGiftRewardsData: null,
  otherGiftRewardsError: null,
};

export const otherGiftRewardsReducer = (state = otherGiftRewardsInitialState, action) => {
  switch (action.type) {
    case OTHER_GIFT_REWARDS.REQUEST:
      return {
        ...state,
        otherGiftRewardsLoading: true,
        otherGiftRewardsData: null,
        otherGiftRewardsError: null,
      };
    case OTHER_GIFT_REWARDS.SUCCESS:
      return {
        ...state,
        otherGiftRewardsLoading: false,
        otherGiftRewardsData: action.payload,
        otherGiftRewardsError: null,
      };
    case OTHER_GIFT_REWARDS.FAIL:
      return {
        ...state,
        otherGiftRewardsLoading: false,
        otherGiftRewardsData: null,
        otherGiftRewardsError: action.payload,
      };
    default:
      return state;
  }
};
