export const SUPPORT = {
    REQUEST: "SUPPORT_REQUEST",
    SUCCESS: "SUPPORT_SUCCESS",
    FAIL: "SUPPORT_FAIL",
  };

  export const TODAY_TICKETS = {
    REQUEST: "TODAY_TICKETS_REQUEST",
    SUCCESS: "TODAY_TICKETS_SUCCESS",
    FAIL: "TODAY_TICKETS_FAIL",
  };

  export const ALL_TICKETS = {
    REQUEST: "ALL_TICKETS_REQUEST",
    SUCCESS: "ALL_TICKETS_SUCCESS",
    FAIL: "ALL_TICKETS_FAIL",
  };

  export const TICKETS_BYID = {
    REQUEST: "TICKETS_BYID_REQUEST",
    SUCCESS: "TICKETS_BYID_SUCCESS",
    FAIL: "TICKETS_BYID_FAIL",
  };

  export const UPDATE_TICKETS_BYID = {
    REQUEST: "UPDATE_TICKETS_BYID_REQUEST",
    SUCCESS: "UPDATE_TICKETS_BYID_SUCCESS",
    FAIL: "UPDATE_TICKETS_BYID_FAIL",
  };
